import React from 'react';
import { connect } from "react-redux";

import Vip from './';

const VipContainer = props => (
  <Vip
    publications={props.publications}
    {...props}
  />
);

const mapStateToProps = state => {
  return {
    publications: state.publications,
  }
};

export default connect(mapStateToProps, {})(VipContainer)
