import React from "react";
import { Link } from "react-router-dom";

import TeamImage from "../../assets/images/team/team.jpg";
import daniel from "../../assets/images/team/daniel.jpg";
import lucia from "../../assets/images/team/lucia.jpg";
import juancruz from "../../assets/images/team/juancruz.jpg";
import marialuz from "../../assets/images/team/marialuz.jpg";
import historyImage from "../../assets/images/history.jpg";
import HistoryIcon from "./icon";

import ROUTES from "../../utils/constants/routes";

import { Helmet } from "react-helmet";

const Items = (props) => {
  return (
    <section className="ourus">
      <Helmet title="M3 Estudio - Sobre nosotros" />
      <section className="ourus__header">
        <h1>Quiénes somos</h1>
      </section>

      <article className="ourus__presentation">
        <img src={TeamImage} alt="Equipo M3 Estudio" />
        <section className="presentation__text-container">
          <p>
            Somos un estudio de arquitectura que parte de la experiencia
            profesional de más de 30 años del arquitecto Juan Daniel Martino. En
            el año 2020, se incorporan su hija Lucía Martino y su hijo Juan Cruz
            Martino, ambos en la etapa final de Arquitectura e Ingeniería Civil,
            respectivamente, y se funda M3 Estudio en la Ciudad de Neuquén.{" "}
            <br />
            <br />
            Día a día nos basamos en la importancia del trabajo en conjunto y en
            la unión de las diferentes perspectivas para el desarrollo de los
            desafíos que propone el cliente o que enfrentamos como empresa.{" "}
            <br />
            <br />
            Compartiendo los valores familiares, formamos un equipo que en cada
            proyecto complementa las diferentes perspectivas, experiencias y
            conocimientos. Nuestro principal objetivo es ser útiles y efectivos,
            transmitir confianza, y mejorar la experiencia de nuestros clientes,
            por lo que el trabajo en conjunto con ellos tiene un rol fundamental
            en la realización del proyecto.
          </p>
          <Link to={ROUTES.OUR_US_DETAIL}>
            <div className="button-default">CONOCER MÁS</div>
          </Link>
        </section>
      </article>

      <section className="ourus__team">
        <ol>
          <li>
            <img src={daniel} alt="" />
          </li>
          <li>
            <img src={lucia} alt="" />
          </li>
          <li>
            <img src={juancruz} alt="" />
          </li>
          <li>
            <img src={marialuz} alt="" />
          </li>
        </ol>
      </section>

      <section className="ourus__history">
        <Link to={ROUTES.HISTORY}>
          <section className="history__container">
            <img src={historyImage} alt="" />
            <section>
              <div>
                <HistoryIcon />
                <p className="container__text">NUESTRA HISTORIA</p>
              </div>
            </section>
          </section>
        </Link>
        <section className="history__image">
          <img src={historyImage} alt="" />
        </section>
      </section>
    </section>
  );
};

export default Items;
