import React, {useEffect, useState} from 'react'
import uuid from 'react-uuid'
import getRowId from "./../../utils/functions/getRowId";
import Slider from "react-slick";
import ReactHtmlParser from "react-html-parser";
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox'

import getDriveImages from './../../utils/functions/get-images'

const Items = props => {
    const { match, publications } = props;
    const [item, setItem] = useState({
        imagenes: '',
        images: []
    })

    useEffect(() => {
        const { params } = match
        const { id, category } = params
        const toItem = publications[category].find(item => getRowId(item['Marca temporal']) === id)
        const images = toItem?.imagenes.split(', ')

        if (toItem) (
            setItem({
                ...toItem,
                images,
            })
        )
    }, [match, publications])

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
    };
    const settingsCarrusel = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        arrows: true,
    };

    const formatText = (text) => {
        return text.replace(/\n/g, "<br />");
    };

    return (
        <section className="vip">
            <div className="vip--mobile">
                <div className="vip__switch">
                    <Slider {...settings}>
                        {
                            item.images.length > 0 && item.images.map(i => (<img key={uuid()} className="vip__image" src={getDriveImages(i)} alt={item.titulo} />))
                        }
                    </Slider>
                    <section className="vip__header">
                        <h1>{item.titulo}</h1>
                    </section>
                </div>
                <section className="vip__descripcion">
                    <h3>{item.descripcion && ReactHtmlParser(formatText(item.descripcion))}</h3>
                    <hr/>
                    <p>{item.detalle && ReactHtmlParser(formatText(item.detalle))}</p>
                </section>
            </div>
            <SimpleReactLightbox>
                <SRLWrapper>
                    <div className="vip--desktop">
                        <section className="vip__header">
                            <h1>{item.titulo}</h1>
                        </section>
                        <article className="vip__container">
                            <section className="container__head">
                                <div className="vip__slider">
                                    <Slider {...settings}>
                                        {
                                            item.images.length > 0 && item.images.map(i => (<img key={uuid()} className="vip__image" src={getDriveImages(i)} alt={item.titulo} />))
                                        }
                                    </Slider>
                                </div>
                                <section className="vip__body">
                                    <h2>{item.nombre}</h2>
                                    <p>{item.descripcion && ReactHtmlParser(formatText(item.descripcion))}</p>
                                </section>
                            </section>

                            <section className="container__carrusel">
                                <Slider {...settingsCarrusel}>
                                    {
                                        item.images.length > 0 && item.images.map(i => (<img key={uuid()} className="vip__image" src={getDriveImages(i)} alt={item.titulo} />))
                                    }
                                </Slider>
                            </section>

                            <section className="container__text">
                                <p>{item.detalle}</p>
                            </section>
                        </article>
                    </div>
                </SRLWrapper>
            </SimpleReactLightbox>
        </section>

    )
}

export default Items