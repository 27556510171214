import React from 'react';

const WhatsApp = ({ number }) => {
  const chat = `https://api.whatsapp.com/send?phone=${number}`;
  return (
    <a href={chat} target="_blank" rel="noopener noreferrer">
      <div className="whatsapp__widget">
        <i className="fa fa-whatsapp" aria-hidden="true" />
      </div>
    </a>
  )
};

export default WhatsApp;
