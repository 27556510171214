import React from 'react';
import Slider from '../../../../components/Slider';

const mockSliders = [
    {
        title: 'Proyecto MF House',
        id: '000001',
        image: 'https://mmh-pre-defined.s3.ap-south-1.amazonaws.com/1543579580_101.JPG'
    },
    {
        title: 'Proyecto MF House',
        id: '000001',
        image: 'https://mmh-pre-defined.s3.ap-south-1.amazonaws.com/1543579580_101.JPG'
    }
]

const Banner = ({ publications }) => {
    return (
        <section className="home__banner" id="inicio">
            <Slider sliders={publications.banner} />
        </section>
    )
};

export default Banner;
