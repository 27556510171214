import React from 'react';
import { connect } from "react-redux";

import Items from './';

const ItemsContainer = props => (
  <Items
    publications={props.publications}
    {...props}
  />
);

const mapStateToProps = state => {
  return {
    publications: state.publications,
  }
};

export default connect(mapStateToProps, {})(ItemsContainer)
