import React, {useEffect} from 'react';
import './assets/scss/index.scss';
import {Switch, Route, BrowserRouter as Router} from 'react-router-dom';
import {connect} from "react-redux";
import {GoogleSpreadsheet} from "google-spreadsheet";

// CONSTANTS
import ROUTES from './utils/constants/routes';
import SOCIAL_NETWORKS from './utils/constants/social-networks';
import {WHATSAPP} from './utils/constants/whatsapp';

// Pages
import Home from './pages/home/container';
import Unavailable from './pages/unavailable';
import Projects from './pages/items/container';
import Vip from './pages/vip/container';
import OurUs from './pages/ourus';
import OurUsDetail from './pages/ourus/detail';
import History from './pages/history/container';

// Components
import Header from './components/Header';
import Footer from './components/Footer';
import WhatsApp from './components/WhatsApp';

// Redux
import {types} from './ducks/Publications';

const App = (props) => {

    useEffect(() => {
        connect()
    }, [])

    const connect = async () => {
        // Initialize the sheet - doc ID is the long id in the sheets URL
        const doc = new GoogleSpreadsheet('17G-3pYjwb_v1ELL6W-rRB8mzxFIPxLwklC2PSiOC0S0');

        //
        await doc.useServiceAccountAuth({
                "type": "service_account",
                "project_id": "inspired-verve-324022",
                "private_key_id": "ac6f4558ff05b6504e502f9ef20bcbcfab82b552",
                "private_key": "-----BEGIN PRIVATE KEY-----\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQDahgUF+ZbcgC+Y\n+tHobDtvDrSzBuoLH82Jy8N6cbPfDrAmaKdAumxmX5CrqESr2B9aRLpKu2ZWry5/\n6B89FFdw+GCy+pwLtv8VAo0C+whlvdbyGoI9IuO3DwFMyGeUkbqn0qgEEM2Egcfy\nAMWci7eiwQUZ7x6n4ANa/0ktT68U1usxW6vbwNhVNqHetSohYwqtz+y0pzguLpbM\n2UaxWmDLWpbGHXkfVcWqIwnaU64WIJaWbNQ9GwAJ85pUMkfy1WHHocLCT0Ejy5dc\nmlAktt33F7fYdS7f4zjuoJAYowUywBMHRotKdRxagJ90L098YvnaIupHiCCOaN+L\nmRweJKehAgMBAAECggEAM5zJ3PJpbmUcWN6M6398vitUBKDScJQvYKdu4SGv4TQ/\n6KJ5NnGRntBdhB23MntWVGS9N19xuXGg4vb5QdznFjij5kIIzz+GmfjDQ3v1LMqc\n1QvrzPLLo/X4UaFXzaBp3K+FnYg2cmCQ0Bjck6/wTJGYWdq/e9kOEdUP/JLAgIQe\nx9uOZvI+xALrJWp2+ZH0ioWIBP+8yRO2RHrZIdX1TLe4mDCDYuqfhmMKkWYZkJVw\nRugOy73xw+y3sP7rhhExOjtYRIzR+Il0EdPMyhveuSJ9zVjYgR1CEhlZ+Y4aZcP4\ngc7RRHGkzUR7PnMwacfc2QTiXOn5l9BMmPtyRpoR0QKBgQDt/c7nBIIF334UpYGK\nwPxeNngd9+UnZGG1R9WPRC956tvZ3mBctd7AFPtk7vjCgiVqE8RDG7S3ng8cp0Ke\n3W9ZPRkKDduVCyTH/0yJKvx9LJgyP290ir8nzo5nzaYiCj5A3C4WsuG72o6KR7wZ\n4fKQ1N2BX3aU2xewO++DKrdDpQKBgQDrDxfnP8qT1ziGNKB9QcoVwlgUbAVdTHJP\najyZVN74oM4I96nd/QnCzMO8VKUc2gFYVPC++oWQvyO5Xitn1trB6iJGB0Z/NG3o\nTMiyrylSRST1OBARIKKM8YIKFByXrbPP/SmjQm/QpilYlfmrgcrIbvC1qkqg21Nc\ncLBwrcLjTQKBgGsm4jgLRItmY7riBaA2Yan9djlv+mGxBXj8PcACYCnRP2kpgVNc\no0zyZXm9So0w0a2f9raMS+J8LKum9wzWACM6qgJn2NyTJt1RKeF8VC/7mw7Mlt7u\n9T/z9RPxeIJNAFhtiea4ZYAR2CmUQLGalH73CEAOfjJpLpIvehghbF/tAoGBAMsc\n0bTbJHs3f/pHw1442RyQQHf1F3WVNzl0PUTpV3pTyXVqPHg7zJFtSr2UDtCteHgR\nfa4o3/1BjOcCflNODt19KXyFkN8eONKAdvQf837JjLPwa8HBB3Tuw0VeN47BZB0X\nT0FpiSEsU5Helk0i7EzhwFHaAn6YxqGcJnpXS44JAoGBAMdCfAt7G2iOU47SbB3H\ngfGhyK0z/aT6i+vTOHMxWfU/l3OZ1BdOwlufSEtiZVUv47LPamSj4sSpCE3rDZZm\n8aJdIZk3QAgbOLlaSb3YBd+ijrZoZQ41kcfvXM2LmC8vef5A4MvCGep7ckiA3oV4\nO2ipqBJ1or9YSJK0nKJfGT0C\n-----END PRIVATE KEY-----\n",
                "client_email": "m3estudio@inspired-verve-324022.iam.gserviceaccount.com",
                "client_id": "110943020747836983338",
                "auth_uri": "https://accounts.google.com/o/oauth2/auth",
                "token_uri": "https://oauth2.googleapis.com/token",
                "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
                "client_x509_cert_url": "https://www.googleapis.com/robot/v1/metadata/x509/m3estudio%40inspired-verve-324022.iam.gserviceaccount.com"
            }
        );

        await doc.loadInfo(); // loads document properties and worksheets

        const data = doc.sheetsByTitle

        const promises = [
            data.Desarrollos.getRows(),
            data.Obras.getRows(),
            data.Proyectos.getRows(),
            data.Banner.getRows(),
            data.Historia.getRows()
        ]

        const results = await Promise.all(promises)

        const pubs = {
            desarrollos: results[0],
            obras: results[1],
            proyectos: results[2],
            banner: results[3],
            historia: results[4]
        }

        props.setPublications(pubs)
    }


    return (
        <Router>
            <Header socialNetworks={SOCIAL_NETWORKS}/>

            <Switch>
                <Route exact path={ROUTES.HOME} component={Home}/>
                <Route exact path={ROUTES.PROJECTS} component={Projects}/>
                <Route exact path={ROUTES.DEVELOPMENTS} component={Projects}/>
                <Route exact path={ROUTES.OBRES} component={Projects}/>
                <Route exact path={ROUTES.VIP} component={Vip}/>
                <Route exact path={ROUTES.OUR_US} component={OurUs}/>
                <Route exact path={ROUTES.OUR_US_DETAIL} component={OurUsDetail}/>
                <Route exact path={ROUTES.HISTORY} component={History}/>
                <Route component={Unavailable}/>
            </Switch>

            <WhatsApp number={WHATSAPP}/>
            <Footer socialNetworks={SOCIAL_NETWORKS}/>
        </Router>
    );
}

const mapStateToProps = state => {
    return {
        publications: state.publications,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setPublications: (publications) => dispatch({type: types.PUBLICATIONS_SET, publications: publications}),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
