// eslint-disable-next-line no-unused-vars
import React from 'react';
import ROUTES from './../../utils/constants/routes';

export default [
  {
    id: 'home',
    text: 'Inicio',
    route: ROUTES.HOME,
  },
  {
    id: 'projects',
    text: 'Proyectos',
    route: ROUTES.PROJECTS,
    children: [
      {
        id: 'projects-houses',
        text: '- Casas',
        route: ROUTES.PROJECTS + '?filter=casas',
      },
      {
        id: 'projects-buildings',
        text: '- Edificios',
        route: ROUTES.PROJECTS + '?filter=edificios',
      },
      {
        id: 'projects-other',
        text: '- Otros',
        route: ROUTES.PROJECTS + '?filter=otro',
      }
    ]
  },
  {
    id: 'obres',
    text: 'Obras',
    route: ROUTES.OBRES,
  },
  {
    id: 'development',
    text: 'Desarrollos',
    route: ROUTES.DEVELOPMENTS,
  },
  {
    id: 'our-us',
    text: 'Quiénes somos',
    route: ROUTES.OUR_US,
    children: [
      {
        id: 'our-us',
        text: '- Historia',
        route: ROUTES.HISTORY,
      }
    ]
  },
  {
    id: 'contact',
    text: 'Contacto',
    route: ROUTES.HOME_WITH_CONTACT, // TODO: cambiar ruta
  },
]
