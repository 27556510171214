import React from 'react';
import { Link } from "react-router-dom";

import NotFoundIcon from './icons/page-not-found';

const Unavailable = () => (
  <section className="unavailable">
    <div className="unavailable__container">
      <NotFoundIcon />
      <p>No se encontró la página solicitada</p>
      <Link to="/">Volver al inicio</Link>
    </div>
  </section>
);

export default Unavailable;
