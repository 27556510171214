import React from 'react';
import SlickSlider from "react-slick";
import classNames from 'classnames';
import uuid from "react-uuid";
import { Oval } from "svg-loaders-react";
import { Link } from "react-router-dom";

import getDriveImages from './../../utils/functions/get-images'

const Slider = props => {
  const { sliders, publication } = props;
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  const content = publication ? [publication]: sliders;
  const sliderContentClasses = classNames('slider__content', { 'slider__content--in-publication': publication });

  return (
    <section className="home__slider">
      <SlickSlider {...settings}>
        {
          (content && content.length > 0)
            ? content.map(slider => {
                return (
                    <div className="slider__slide" key={uuid()}>
                        <figure className="slider__bg" >
                            <div className="slider__filter" />
                            <img src={getDriveImages(slider.imagen)} alt={slider.titulo} />
                        </figure>
                        <section className={sliderContentClasses}>
                            <div className="slider__content-info">
                                <h2>{slider.titulo}</h2>
                                {slider.link && <Link to={slider.id}><div className="slider__button">VER MÁS >></div></Link>}
                            </div>
                        </section>
                    </div>
                )
              })
            : <section className="home__slider-loading">
            <Oval />
          </section>
        }
      </SlickSlider>
    </section>
  )
};

export default Slider;
