import React from 'react';
import PropTypes from 'prop-types';
import logo from './../../assets/images/logo.png';

const Footer = props => (
  <footer>
    <div className="footer__container">
      <img src={logo} alt="Mautone logo"/>
      <div>
        <h2>M3 Estudio</h2>
        <h3>Estudio de arquitectura</h3>
        <h4>Neuquén Capital</h4>
      </div>
    </div>
  </footer>
);

Footer.propTypes = {
  socialNetworks: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }))
};

export default Footer;
