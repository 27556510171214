import React from "react";

import Banner from "./components/Banner";
import Proyects from "./components/Projects";
import Numbers from "./components/Numbers";
import Us from "./components/Us";
import Contact from "./components/Contact";

import { Helmet } from "react-helmet";

const Home = (props) => {
  const { publications } = props;

  return (
    <>
      <Helmet title="M3 Estudio - Inicio" />
      <div className="home">
        <Banner publications={publications} />
        <Proyects />
        <div className="switch">
          <Numbers />
          <Us />
        </div>
        <Contact />
      </div>
    </>
  );
};

Home.defaultProps = {
  publications: [],
};

export default Home;
