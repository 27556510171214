export default [
  {
    icon: 'fa fa-facebook',
    link: '#',
  },
  {
    icon: 'fa fa-twitter',
    link: '#',
  },
  {
    icon: 'fa fa-linkedin',
    link: '#',
  },
  {
    icon: 'fa fa-youtube',
    link: '#',
  },
]
