import React from 'react'

const History = () => (
  <svg className="ourus__icon" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px"
       y="0px"
       viewBox="0 0 512.002 512.002">
  <g>
  <g>
  <path d="M234.429,112.065H130.804c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5h103.624c4.142,0,7.5-3.358,7.5-7.5
C241.929,115.423,238.571,112.065,234.429,112.065z"/>
</g>
</g>
      <g>
<g>
<path d="M375.281,364.364h-55.476c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5h55.476c4.142,0,7.5-3.358,7.5-7.5
C382.781,367.722,379.423,364.364,375.281,364.364z"/>
</g>
</g>
      <g>
<g>
<path d="M476.245,136.326c3.624,2.004,8.188,0.691,10.193-2.933c14.702-26.581,23.534-53.597,25.54-78.128
c0.369-4.544-3.546-8.457-8.089-8.086c-28.616,2.34-59.95,13.913-89.961,32.568c-3.246-13.176-7.346-23.47-12.447-31.253
c-7.837-11.958-18.061-18.023-30.387-18.023H43.788c-15.5,0-27.203,4.981-34.78,14.807C-5.7,64.345,1.95,94.338,2.283,95.606
c0.866,3.296,3.846,5.595,7.254,5.595h66.02c6.706,42.678,6.364,103.367,6.047,159.227c-0.161,28.362-0.328,57.689,0.353,84.417
c0.105,4.14,3.54,7.403,7.688,7.307c4.141-0.105,7.412-3.547,7.307-7.688c-0.674-26.494-0.508-55.702-0.348-83.949
c0.38-66.939,0.746-131.33-8.622-174.825c-0.892-4.139-1.907-8.261-3.094-12.326c-3.305-11.354-7.507-20.805-12.848-27.891
h299.054c13.59,0,23.251,14.091,29.469,43.043c-8.094,5.693-15.988,11.883-23.643,18.491
c-17.119,14.777-32.926,31.627-46.52,49.891H130.804c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5h189.147
c-5.536,8.5-10.51,17.126-14.865,25.784H130.804c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5h167.362
c-3.623,8.647-6.594,17.272-8.887,25.784H130.804c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5h155.121
c-0.625,3.574-1.103,7.112-1.46,10.615l-23.164,23.164c-2.929,2.929-2.929,7.678,0,10.606c2.836,2.835,7.689,2.918,10.607,0
l23.154-23.154c13.906-1.411,27.557-4.828,40.704-9.524c13.532-4.834,26.56-11.046,38.995-18.236
c11.985-6.93,23.434-14.783,34.292-23.37c-0.005,12.275-0.075,24.709-0.145,36.855c-0.306,53.904-0.622,109.516,4.894,150.379
c0,0-248.45,0-248.749,0c-4.753,0-8.359,4.542-7.302,9.168c0.017,0.074,0.025,0.149,0.044,0.223
c0.002,0.009,0.014,0.056,0.031,0.124c0.416,1.733,5.488,24.014-3.762,36.745c-0.027,0.038-0.054,0.076-0.082,0.114
c-5.326,7.232-14.743,9.402-23.272,9.354c-18.227-0.103-28.873-30.304-32.544-92.32c-0.245-4.134-3.792-7.285-7.93-7.043
c-4.135,0.245-7.289,3.795-7.043,7.93c2.933,49.554,10.996,106.434,47.611,106.434h327.306c15.5,0,27.203-4.981,34.78-14.807
c14.706-19.068,7.056-49.061,6.723-50.329c-0.866-3.296-3.846-5.595-7.254-5.595h-63.408c-5.665-39.92-5.35-95.978-5.042-150.293
c0.093-16.359,0.187-33.231,0.121-49.581c4.17-3.744,8.269-7.622,12.279-11.632c12.512-12.512,23.876-25.94,33.773-39.912
c2.394-3.38,1.595-8.061-1.785-10.456c-3.38-2.394-8.061-1.595-10.456,1.785c-9.41,13.283-20.223,26.061-32.139,37.976
c-9.394,9.394-19.414,18.179-29.979,26.235c-25.809,19.67-53.433,33.798-79.686,40.703c-0.845,0.221-1.694,0.428-2.542,0.637
L446.78,122.979c2.929-2.929,2.929-7.678,0-10.606c-2.929-2.929-7.678-2.929-10.606,0L302.89,245.656
c0.191-0.775,0.374-1.548,0.578-2.325c7.655-29.298,22.357-56.044,40.745-79.88c0.007-0.009,0.013-0.017,0.02-0.026
c0.057-0.075,0.116-0.149,0.173-0.223c17.148-22.423,37.502-42.413,60.249-59.131c29.873-21.906,61.914-36.383,91.291-40.864
c-3.054,19.957-10.79,41.513-22.634,62.926C471.307,129.758,472.62,134.321,476.245,136.326z M72.724,86.198H15.738v0.001
c-0.352-2.425-0.672-5.473-0.74-8.809c-0.138-6.824,0.775-16.352,5.911-22.984c4.598-5.938,11.954-8.863,22.48-8.935l4.91,0.54
c0.071,0.017,0.141,0.035,0.211,0.053c9.918,2.584,17.662,14.494,23.093,35.477C72.003,83.087,72.372,84.641,72.724,86.198z
M486.142,425.801c1.138,7.949,1.966,22.564-5.128,31.762c-4.654,6.034-12.146,8.967-22.903,8.967H165.733
c9.646-12.683,9.518-30.105,8.252-40.729H486.142z"/>
</g>
</g>
      <g>
</g>
      <g>
</g>
      <g>
</g>
      <g>
</g>
      <g>
</g>
      <g>
</g>
      <g>
</g>
      <g>
</g>
      <g>
</g>
      <g>
</g>
      <g>
</g>
      <g>
</g>
      <g>
</g>
      <g>
</g>
      <g>
</g>
</svg>
)

export default History