export default {
  HOME: '/',
  HOME_WITH_CONTACT: '/#contacto',
  ERROR: '/error',
  OUR_US: '/nosotros',
  OUR_US_DETAIL: '/nosotros/detalle',
  HISTORY: '/historia',
  SERVICES: '/#services',
  PROJECTS: '/proyectos',
  OBRES: '/obras',
  DEVELOPMENTS: '/desarrollos',
  VIP: '/:category/:name/:id'
}
