import React from 'react';
import { WHATSAPP } from './../../../../utils/constants/whatsapp';

const Projects = () => (
  <section className="contact" id="contacto">
      <section className="container">
        <h2>CONTACTANOS</h2>

        <ol className="networks">
            <li>
                <a href="https://www.instagram.com/m3_estudio/?hl=es-la ">
                    <i className="fa fa-instagram" aria-hidden="true" />
                </a>
            </li>
            <li>
                <a href="https://ar.linkedin.com/company/m3estudio ">
                    <i className="fa fa-linkedin" aria-hidden="true" />
                </a>
            </li>
            <li>
                <a href="https://www.facebook.com/M3-Estudio-169209504742918/ ">
                    <i className="fa fa-facebook" aria-hidden="true" />
                </a>
            </li>
            <li>
                <a href="https://www.youtube.com/channel/UCvHL3QB8tV2b52iV3VowM-g/featured ">
                    <i className="fa fa-youtube" aria-hidden="true" />
                </a>
            </li>
            <li>
                <a href={`https://api.whatsapp.com/send?phone=${WHATSAPP}`} target="_blank" rel="noopener noreferrer">
                    <i className="fa fa-whatsapp" aria-hidden="true" />
                </a>
            </li>
        </ol>
        <div className="separator" />
        <ol className="data-contact">
            <div>
                <li>
                    <i className="fa fa-envelope" aria-hidden="true" />
                    <h3>martino.m3estudio@gmail.com</h3>
                </li>
                <li>
                    <i className="fa fa-map-marker" aria-hidden="true" />
                    <h3>Caviahue 328 4ºC - Neuquén Capital</h3>
                </li>
            </div>
        </ol>
      </section>
  </section>
);

export default Projects;
