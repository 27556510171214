import React from 'react';
import { connect } from "react-redux";

import Home from './';

const HomeContainer = props => (
  <Home
    publications={props.publications}
  />
);

const mapStateToProps = state => {
  return {
    publications: state.publications,
  }
};

export default connect(mapStateToProps, {})(HomeContainer)
