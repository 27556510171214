import React  from 'react'

import TeamImage from '../../assets/images/team/team.jpg'
import daniel from "../../assets/images/team/daniel.jpg";
import lucia from "../../assets/images/team/lucia.jpg";
import juancruz from "../../assets/images/team/juancruz.jpg";
import marialuz from "../../assets/images/team/marialuz.jpg";
import historyImage from "../../assets/images/history.jpg";
import HistoryIcon from './icon'

const Items = props => {
  return (
    <section className="ourus">
      <section className="ourus__header">
        <h1>Quiénes somos</h1>
      </section>

      <article className="ourus__presentation detail__container">
        <img src={TeamImage} alt="Equipo M3 Estudio" />
        <section className="presentation__text-container detail">
          <p>
            Somos un estudio de arquitectura que parte de la experiencia profesional de más de 30 años del arquitecto Juan Daniel Martino. En el año 2020, se incorporan su hija Lucía Martino y su hijo Juan Cruz Martino, ambos en la etapa final de Arquitectura e Ingeniería Civil, respectivamente, y se funda M3 Estudio en la Ciudad de Neuquén. <br/><br/>
            Día a día nos basamos en la importancia del trabajo en conjunto y en la unión de las diferentes perspectivas para el desarrollo de los desafíos que propone el cliente o que enfrentamos como empresa. <br/><br/>
            Compartiendo los valores familiares, formamos un equipo que en cada proyecto complementa las diferentes perspectivas, experiencias y conocimientos. Nuestro principal objetivo es ser útiles y efectivos, transmitir confianza, y mejorar la experiencia de nuestros clientes, por lo que el trabajo en conjunto con ellos tiene un rol fundamental en la realización del proyecto. <br/><br/>
            <h2>Misión</h2> <br/>
            Como estudio de arquitectura, nos dedicamos al diseño y obra de casas, edificios u otros usos. Al mismo tiempo, buscamos innovadores desafíos de inversiones propios y para el cliente. La experiencia y el conocimiento de sus integrantes son recursos claves para convertir los problemas en soluciones creativas. <br/><br/>
            <h2>Visión</h2> <br/>
            Aspiramos a crecer como empresa, generando una identidad basada en los valores fundamentales del estudio. A su vez, buscamos sumar recursos, desarrollos y proyectos laborales. Con el cliente y su experiencia como motor de inspiración, nuestro trabajo, desde que se acerca al estudio, apunta a cumplir su deseo de la mejor manera posible, invitándolo a confiar en el equipo y en los conocimientos y experiencias previas que cada integrante posee. <br/><br/>
            <h2>Valores</h2> <br/>
            ● Confianza y honestidad. <br/>
            ● Respeto y profesionalidad. <br/>
            ● Trabajo en equipo interdisciplinario. <br/>
            ● Experiencia y conocimiento. <br/>
            ● Búsqueda de la perfección. <br/>
            ● Adaptación a los requerimientos. <br/>
            ● Calidad en la experiencia del cliente.
          </p>
        </section>
      </article>
    </section>
  )
}

export default Items