const getDriveImages = function (image = "") {
  // Intentar extraer el ID del archivo para ambos formatos
  const regexD = /\/d\/(.+?)\//; // Para el formato /d/
  const regexId = /[?&]id=([^&]+)/; // Para el formato ?id=

  let fileId;

  // Verifica el formato /d/
  const matchD = image.match(regexD);
  if (matchD) {
    fileId = matchD[1];
  } else {
    // Verifica el formato ?id=
    const matchId = image.match(regexId);
    if (matchId) {
      fileId = matchId[1];
    } else {
      return "";
    }
  }

  // Generar el enlace directo de imagen
  return `https://lh3.googleusercontent.com/d/${fileId}`;
};

export default getDriveImages;
