import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import getDriveImages from "./../../utils/functions/get-images";
import Skeleton from "@material-ui/lab/Skeleton";
import getRowId from "../../utils/functions/getRowId";
import convertNameToFriendlyUrl from "../../utils/functions/conver-name-to-friendly-url";
import { Helmet } from "react-helmet";

const handleRedirect = (publication) => {
  const convertUrl = getRowId(publication["Marca temporal"]);
  return `${convertNameToFriendlyUrl(publication.titulo)}/${convertUrl}`;
};

const getPublicationsFromParams = (publications, pathname) => {
  switch (pathname) {
    case "/proyectos":
      return {
        title: "PROYECTOS",
        items: publications.proyectos,
      };
    case "/obras":
      return {
        title: "OBRAS",
        items: publications.obras,
      };
    case "/desarrollos":
      return {
        title: "DESARROLLOS",
        items: publications.desarrollos,
      };
    case "/historia":
      return {
        title: "HISTORIA",
        items: publications.historia,
      };
  }
};

const Items = (props) => {
  const [filter, setFilter] = useState("todos");
  const [items, setItems] = useState([]);
  const { publications, location } = props;
  const { items: itemsNoFormatted, title } = getPublicationsFromParams(
    publications,
    location.pathname
  );

  useEffect(() => {
    // order by desc
    const items = itemsNoFormatted.sort(
      (a, b) => getRowId(b["Marca temporal"]) - getRowId(a["Marca temporal"])
    );

    setItems(items);
  }, [itemsNoFormatted]);

  const itemsClasses = classNames("items", {
    items__developments: title === "DESARROLLOS",
  });

  useEffect(() => {
    const { location } = props;

    if (location.search.includes("filter")) {
      if (location.search.includes("casas")) {
        selectFilter("casas");
      } else if (location.search.includes("edificios")) {
        selectFilter("edificios");
      } else if (location.search.includes("otro")) {
        selectFilter("otro");
      }
    }
  }, [itemsNoFormatted]);

  const selectFilter = (category) => {
    setFilter(category);

    const { items: realItems } = getPublicationsFromParams(
      publications,
      location.pathname
    );
    if (category === "todos") {
      setItems(realItems);
    } else {
      const copyItems = realItems.filter((item) => item.tipo === category);

      setItems(copyItems);
    }
  };

  const getFilters = () => {
    const casasClassNames = classNames({
      filter__selected: filter === "casas",
    });
    const edificiosClassNames = classNames({
      filter__selected: filter === "edificios",
    });
    const otrosClassNames = classNames({ filter__selected: filter === "otro" });
    const todosClassNames = classNames({
      filter__selected: filter === "todos",
    });

    return (
      <section className="items__filters">
        <ol>
          <li className={casasClassNames} onClick={() => selectFilter("casas")}>
            Casas
          </li>
          <li
            className={edificiosClassNames}
            onClick={() => selectFilter("edificios")}
          >
            Edificios
          </li>
          <li className={otrosClassNames} onClick={() => selectFilter("otro")}>
            Otros
          </li>
          <li className={todosClassNames} onClick={() => selectFilter("todos")}>
            Todos
          </li>
        </ol>
      </section>
    );
  };

  const isProyectsPage = title === "PROYECTOS";

  return (
    <>
      <Helmet
        title={`M3 Estudio - ${
          isProyectsPage
            ? "Proyectos"
            : title === "DESARROLLOS"
            ? "Desarrollos"
            : "Obras"
        } `}
      />
      <div className="items__header">
        <h1>{title}</h1>
      </div>
      {isProyectsPage && getFilters()}
      <section className={itemsClasses}>
        {items.length > 0 ? (
          items.map((item) => (
            <Link to={`${location.pathname}/${handleRedirect(item)}`}>
              <article>
                <img
                  src={getDriveImages(item.imagen_principal)}
                  alt={item.nombre}
                />
                <div className="item__container">
                  <h2>{item.nombre}</h2>
                </div>
              </article>
            </Link>
          ))
        ) : (
          <>
            <Skeleton className="item__skeleton" animation="wave" />
            <Skeleton className="item__skeleton" animation="wave" />
            <Skeleton className="item__skeleton" animation="wave" />
            <Skeleton className="item__skeleton" animation="wave" />
            <Skeleton className="item__skeleton" animation="wave" />
            <Skeleton className="item__skeleton" animation="wave" />
          </>
        )}
      </section>
    </>
  );
};

export default Items;
