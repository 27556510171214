import React from 'react'

const Mate = () => (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
         viewBox="0 0 512 512">
<g>
	<g>
		<path d="M425.374,38.052L413.19,5.544c-0.795-2.122-2.401-3.841-4.464-4.778c-2.063-0.937-4.414-1.018-6.534-0.222l-76.61,28.729
			c-7.977,3.004-13.963,9.718-16.011,17.944l-14.783,58.936c-1.147,4.577,1.632,9.217,6.208,10.365
			c4.58,1.145,9.217-1.633,10.365-6.208l14.784-58.951c0.695-2.793,2.736-5.072,5.446-6.094l68.599-25.725l6.188,16.509
			l-60.08,22.53c-2.628,0.985-4.604,3.198-5.286,5.921l-15.446,61.577H177.603c-26.798,0-48.598,21.802-48.598,48.599
			c0,6.2,2.228,11.888,5.923,16.31c-7.244,9.86-13.952,20.481-19.976,31.649c-2.242,4.152-0.691,9.334,3.461,11.573
			c1.29,0.696,2.679,1.026,4.048,1.026c3.039,0,5.982-1.626,7.525-4.488c5.938-11.007,12.577-21.428,19.752-31.044
			c1.538,0.29,3.121,0.45,4.742,0.45h203.04c14.047,0,25.476-11.429,25.476-25.476c0-23.866-17.297-43.755-40.01-47.824
			l13.508-53.849l63.88-23.956C424.791,47.393,427.029,42.47,425.374,38.052z M365.911,174.676c0,4.628-3.764,8.391-8.391,8.391
			H154.48c-4.627,0-8.391-3.764-8.391-8.391c0-1.522,0.115-3.018,0.325-4.483h146.039c4.718,0,8.543-3.825,8.543-8.543
			s-3.825-8.543-8.543-8.543H154.657c5.751-6.115,13.907-9.946,22.945-9.946h156.795C351.775,143.162,365.911,157.3,365.911,174.676
			z"/>
	</g>
</g>
            <g>
	<g>
		<path d="M386.729,205.073c-2.539-3.976-7.822-5.14-11.798-2.602c-3.976,2.54-5.14,7.822-2.602,11.798
			c24.748,38.748,39.524,85.399,39.524,124.79c0,85.939-69.916,155.855-155.855,155.855S100.144,424.998,100.144,339.06
			c0-24.476,5.582-51.701,16.144-78.729c1.718-4.394-0.452-9.349-4.846-11.067c-4.394-1.715-9.349,0.454-11.065,4.848
			c-11.329,28.99-17.316,58.364-17.316,84.948C83.06,434.419,160.64,512,256,512s172.94-77.58,172.94-172.94
			C428.94,296.543,413.16,246.453,386.729,205.073z"/>
	</g>
</g>
            <g>
	<g>
		<path d="M256,253.231c-52.227,0-94.716,42.49-94.716,94.717c0,52.227,42.49,94.716,94.716,94.716
			c26.863,0,52.565-11.474,70.516-31.481c3.152-3.512,2.859-8.913-0.653-12.063c-3.512-3.152-8.913-2.86-12.063,0.653
			c-14.716,16.4-35.783,25.805-57.8,25.805c-42.806,0-77.631-34.825-77.631-77.631s34.825-77.632,77.631-77.632
			s77.632,34.825,77.632,77.632c0,10.568-2.083,20.809-6.193,30.438c-1.852,4.341,0.165,9.36,4.505,11.211
			c4.337,1.851,9.358-0.166,11.21-4.505c5.018-11.761,7.563-24.258,7.563-37.143C350.717,295.72,308.227,253.231,256,253.231z"/>
	</g>
</g>
            <g>
	<g>
		<path d="M342.995,153.108h-27.17c-4.718,0-8.543,3.825-8.543,8.543s3.825,8.543,8.543,8.543h27.17
			c4.718,0,8.543-3.825,8.543-8.543S347.713,153.108,342.995,153.108z"/>
	</g>
</g>
            <g>
	<g>
		<path d="M300.339,341.907l-38.299-38.299c-1.601-1.603-3.775-2.502-6.04-2.502c-2.266,0-4.439,0.9-6.04,2.502l-38.299,38.299
			c-3.336,3.336-3.336,8.745,0,12.08l38.299,38.299c1.667,1.669,3.854,2.502,6.04,2.502c2.186,0,4.373-0.834,6.04-2.502
			l38.299-38.299c1.603-1.601,2.502-3.775,2.502-6.04S301.942,343.509,300.339,341.907z M256,374.166l-26.219-26.219L256,321.729
			l26.219,26.219L256,374.166z"/>
	</g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
            <g>
</g>
</svg>
)

export default Mate