import React from 'react';

import House from './icons/house';
import Hours from './icons/hours';
import Mate from './icons/mate';

const Numbers = () => (
  <section className="numbers" id="en-numeros">
    <section className="container">
      <h2>EN NÚMEROS</h2>
      <ol>
        <li>
          <House />
          <div>
            <h3>5470</h3>
            <h4>m2 de obra</h4>
          </div>
        </li>
        <li>
          <Mate />
          <div>
            <h3>2896</h3>
            <h4>Rondas de mate</h4>
          </div>
        </li>
        <li>
          <Hours />
          <div>
            <h3>154900</h3>
            <h4>Horas de trabajo</h4>
          </div>
        </li>
      </ol>
    </section>
  </section>
);

export default Numbers;
