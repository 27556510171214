import React from 'react';

import daniel from './../../../../assets/images/team/daniel.jpg'
import juancruz from './../../../../assets/images/team/juancruz.jpg'
import lucia from './../../../../assets/images/team/lucia.jpg'

const Us = () => (
  <section className="us" id="nosotros">
      <h2>QUIÉNES SOMOS</h2>
      <ol>
          <li>
              <img src={daniel} alt=""/>
              <div>Daniel</div>
          </li>
          <li>
              <img src={lucia} alt=""/>
              <div>Lucia</div>
          </li>
          <li>
              <img src={juancruz} alt=""/>
              <div>Juan Cruz</div>
          </li>
      </ol>
  </section>
);

export default Us;
