import React from "react";
import { Link } from "react-router-dom";

import getDriveImages from "../../utils/functions/get-images";
import ROUTES from "../../utils/constants/routes";
import Skeleton from "@material-ui/lab/Skeleton";
import getRowId from "../../utils/functions/getRowId";
import convertNameToFriendlyUrl from "../../utils/functions/conver-name-to-friendly-url";

import { Helmet } from "react-helmet";

const handleRedirect = (publication) => {
  const convertUrl = getRowId(publication["Marca temporal"]);
  return `${convertNameToFriendlyUrl(publication.titulo)}/${convertUrl}`;
};

const Items = ({ publications, location }) => {
  const items = publications.historia;
  return (
    <section className="history">
      <Helmet title="M3 Estudio - Historia" />
      <section className="history__header">
        <h1>NUESTRA HISTORIA</h1>
      </section>
      <section className="history__container">
        <section className="container__text">
          <p>
            Corría el año 1987, cuando Juan Daniel Martino, recién recibido de
            arquitecto, llegó a la Ciudad de Neuquén y comenzó sus primeros
            pasos profesionales en el estudio AIASA. Junto a ellos, llevó a cabo
            grandes desafíos de proyectos y obras que le dieron gran parte de la
            experiencia profesional. Algunos de ellos son conocidos por los
            residentes de la Región del Alto Valle, tales como el Estadio
            Polideportivo Ruca Che, el salón para eventos infantiles La Máquina
            de Jugar, conjuntos de viviendas en Viedma, el centro comercial de
            Bariloche, el centro comercial Torres del Parque en San Martín de
            los Andes, entre otros. <br />
            <br />
            En el año 2010, el arquitecto decide realizar un gran cambio en su
            vida y comienza a formar su propio estudio. Con otro rumbo, pero el
            mismo camino de formación y especialización busca ofrecer la mejor
            respuesta a sus clientes. <br />
            <br />
            En la actualidad, junto con sus hijos, Lucía y Juan Cruz, se
            mantiene en el camino de la profesionalización y comparte esta
            pasión que los une.
          </p>
        </section>
        <section className="container__publications">
          {items.length > 0 ? (
            items.map((item) => (
              <Link to={`${ROUTES.HISTORY}/${handleRedirect(item)}`}>
                <article>
                  <img
                    src={getDriveImages(item.imagen_principal)}
                    alt={item.nombre}
                  />
                  <div className="item__container">
                    <h2>{item.nombre}</h2>
                  </div>
                </article>
              </Link>
            ))
          ) : (
            <>
              <Skeleton className="item__skeleton" animation="wave" />
              <Skeleton className="item__skeleton" animation="wave" />
              <Skeleton className="item__skeleton" animation="wave" />
              <Skeleton className="item__skeleton" animation="wave" />
              <Skeleton className="item__skeleton" animation="wave" />
              <Skeleton className="item__skeleton" animation="wave" />
            </>
          )}
        </section>
      </section>
    </section>
  );
};

export default Items;
