import React from 'react';
import { Link } from "react-router-dom";
import MdMenu from 'react-ionicons/lib/MdMenu';
import {
  Drawer,
  Hidden
} from '@material-ui/core'

import logo from './../../assets/images/logo.png';

import menu from './wordings';

class Header extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      active: false
    };

    this.handleOpenMenu = this.handleOpenMenu.bind(this);
  }

  handleOpenMenu() {
    this.setState({ active: !this.state.active })
  }

  render() {

    return (
      <React.Fragment>
        <header>
          <nav className="header__nav">
            <Link to="/">
              <img src={logo} alt="M3 Estudio" className="header__logo" />
            </Link>

            <Hidden only={['sm', 'xs']}>
              <ol className="header__menu">
                {
                  menu.map(item => (
                    <li>
                      <a href={item.route}>{item.text}</a>
                      {
                        item.children && <ul>
                          {
                            item.children.map(children => (
                                <li>
                                  <a href={children.route}>{children.text}</a>
                                </li>
                            ))
                          }
                        </ul>
                      }
                    </li>
                  ))
                }
              </ol>
            </Hidden>

            <Hidden only={['md', 'lg', 'xl']}>
              <div className="header__hamburger" onClick={this.handleOpenMenu}>
                <MdMenu color="#AB2328" fontSize={28} />
              </div>

              <Drawer anchor="right" open={this.state.active} onClose={this.handleOpenMenu}>
                <ul className="sidebar__list">
                  {
                    menu.map(item => (
                      <a className="header__link" href={item.route}>
                        <li>
                          {item.text}
                          {
                            item.children && <ul>
                              {
                                item.children.map(children => (
                                  <a className="header__link" href={item.route}>
                                    <li>{children.text}</li>
                                  </a>
                                ))}
                            </ul>
                          }
                        </li>
                      </a>
                    ))
                  }
                </ul>
              </Drawer>
            </Hidden>



          </nav>
        </header>
      </React.Fragment>
    )
  }
}

export default Header;
